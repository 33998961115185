import React from 'react';
import { Reference } from 'types/reference';
import { NextFrontasticImage } from 'frontastic/lib/image';
import LoginForm from './login-form';
export interface LoginProps {
  signInLink: Reference;
  accountLink?: Reference;
  logo: NextFrontasticImage;
  src: string;
  onLogin?: () => void;
}

const Login: React.FC<LoginProps> = ({ onLogin, logo, src }) => {
  return (
    <div className="m-auto grid max-w-[300px] px-16">
      <LoginForm onLogin={onLogin} logo={logo} src={src} />
    </div>
  );
};

export default Login;
