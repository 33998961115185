import React, { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import Login, { LoginProps } from 'components/commercetools-ui/organisms/authentication/login';
import Tile from 'components/commercetools-ui/organisms/authentication/tile';
import Redirect from 'helpers/redirect';
import { useAccount } from 'frontastic/hooks';
import { Reference } from 'types/reference';

export interface Props {
  data: any;
}

const AccountLoginTastic: React.FC<Props> = ({ data }) => {
  const { backgroundImage, backgroundImageWidth, shortText, icon, title, logo, backgroundColor } =
    data?.data?.dataSource?.fields;
  const [imageWidth, setImageWidth] = useState(0);
  const router = useRouter();
  const { loggedIn } = useAccount();

  const accountDataLinks: LoginProps = {
    accountLink: '/' as unknown as Reference,
    signInLink: '/login' as unknown as Reference,
    logo: logo?.fields?.file?.url.toString(),
    src: logo?.fields?.file?.url.toString(),
  };

  const redirectLink = useMemo(() => {
    const lastVisitedPage = router.query.lvp ? `/${router.query.lvp}` : accountDataLinks.accountLink;
    return lastVisitedPage;
  }, [data.accountLink, router]);

  const getImageWidth = () => {
    setImageWidth(Number(backgroundImageWidth) - 100);
  };

  useEffect(() => {
    if (backgroundImageWidth) {
      getImageWidth();
    }
  }, [backgroundImageWidth]);

  if (loggedIn) return <Redirect target={redirectLink} />;

  return (
    <div className={`relative flex h-screen items-center justify-center bg-center`}>
      <div
        className="absolute inset-0"
        style={{
          zIndex: -1,
          backgroundColor: `#${backgroundColor}`,
        }}
      ></div>
      <img
        src={backgroundImage?.fields?.file?.url.toString()}
        alt="Background"
        className="absolute left-0 top-0 h-full w-full object-cover"
        color="#FBF1E5"
        style={{
          zIndex: -1,
          transform: `translateX(${imageWidth}%)`, // Move the visible part of the image to 1/3 of the width
        }}
      />
      <div className="grid grid-cols-1 items-center gap-48 md:grid-cols-3 md:gap-32" style={{ zIndex: 1 }}>
        <div className=""></div>
        <div className="rounded-lg bg-white px-16 py-28 lg:p-[48px]">
          <Login {...accountDataLinks} />
        </div>
        <div className="hidden max-w-[320px] md:block">
          <Tile icon={icon.fields?.file?.url?.toString()} text={shortText} title={title} />
        </div>
      </div>
    </div>
  );
};

export default AccountLoginTastic;
