import React from 'react';

interface TileProps {
  icon: string;
  title: string;
  text: string;
}

const Tile: React.FC<TileProps> = ({ icon, title, text }) => {
  return (
    <div className="flex flex-col items-center justify-center text-center">
      <img className="mb-2" src={icon}></img>
      <h3 className="mt-24 text-center text-5xl font-normal leading-10 text-[#0C9D00] underline">{title}</h3>
      <div className="mt-24 max-w-[250px]">
        <p className="text-center text-xs font-bold leading-none text-[#0C9D00]">{text}</p>
      </div>
    </div>
  );
};

export default Tile;

<div className="w-64 text-center text-xs font-bold leading-none text-green-700">
  Sit proin pretium justo amet massa pro um luctus proin pulvinar.
</div>;
